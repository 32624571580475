import React from "react";
import HomeIcon from "../../../../assets/icons/home_icon.svg";
import QuestionMarkIcon from "../../../../assets/icons/question_mark_icon.svg";
import ArrowLeftIcon from "../../../../assets/icons/arrow_left_icon.svg";

import * as S from "./styles";
import { RemoteConfig } from "../../../shared/types";
import { RemoteConfigService } from "../../../services";

export interface NavItem {
  name: string;
  path: string;
  fullUrl: string;
}

const ActionBar: React.FunctionComponent = () => {
  const [remoteConfig, setRemoteConfig] = React.useState<RemoteConfig>(
    {} as RemoteConfig
  );
  const getRemoteConfig = React.useCallback(async () => {
    const data = await new RemoteConfigService().getRemoteConfig();
    setRemoteConfig(data);
  }, []);
  React.useEffect(() => {
    getRemoteConfig();
  }, [getRemoteConfig]);

  const resetChartState = React.useCallback(() => {
    window.location.replace(window.location.origin);
  }, []);

  return (
    <S.Container>
      <div
        className="dv-icon__home icon-holder"
        title="Home"
        onClick={() => resetChartState()}
      >
        <img src={HomeIcon} alt="a" style={{ width: 30, height: 30 }} />
      </div>
      <div
        className="dv-icon__question icon-holder"
        title="Help"
        onClick={() =>
          window.open(remoteConfig.action_bar_help_button_url, "_blank")
        }
      >
        <img src={QuestionMarkIcon} alt="a" style={{ width: 30, height: 30 }} />
      </div>
      <div
        className="dv-icon__arrow_left icon-holder"
        title="Go Back"
        onClick={() =>
          window.open(remoteConfig.action_bar_help_button_url, "_blank")
        }
      >
        <img src={ArrowLeftIcon} alt="a" style={{ width: 30, height: 30 }} />
      </div>
    </S.Container>
  );
};

export { ActionBar };
