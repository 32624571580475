import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const AppSlice = createSlice({
  name: "app-slice",
  initialState: {
    showModal: false,
  },

  reducers: {
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const { setShowModal } = AppSlice.actions;
export default AppSlice.reducer;
