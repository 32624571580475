import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 16px;
  text-align: left;
  padding-left: 24px;
  padding-right: 16px;
  margin-right: 16px;
  z-index: 1000;
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #2e2e2e;
    border: 5px solid transparent;
    background-clip: content-box;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2e2e2e;
    height: 10px;
    border: 1px solid #2e2e2e;

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  p {
    color: white;
    font-family: "Inter";
    font-weight: 300;
    font-size: 1.2em;
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
