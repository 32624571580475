import React from "react";

import * as S from "./styles";

export interface NavItem {
  name: string;
  path: string;
  fullUrl: string;
}

const NavBar: React.FunctionComponent = () => {
  const [navItems, setNavItems] = React.useState<Array<NavItem>>([]);
  const [currentURL, setCurrentURL] = React.useState<string>(
    window.location.href ?? ""
  );

  const getSectionsInUrl = React.useCallback((): NavItem[] => {
    const codes = currentURL.split("/");
    const codesInURL = codes.filter(
      (code) =>
        code.length > 6 &&
        !code.includes("digital-navigato") &&
        !code.includes("localhost")
    );
    var newSections: NavItem[] = [
      {
        name: "Navigate",
        path: "/",
        fullUrl: window.location.protocol + "://" + window.location.host + "/",
      },
    ];
    for (var i = 0; i < codesInURL.length; i++) {
      const nameRaw = codesInURL[i].split("[[")[0];
      const name = nameRaw
        .replace(/%20/g, "")
        .replace(" ", "")
        .replace("-", "");

      const path = `/${codesInURL[i]}`;

      var fullUrl = window.location.host;
      if (i > 0) {
        fullUrl =
          window.location.host + "/" + codesInURL.slice(0, i + 1).join("/");
      } else if (i <= 0) {
        fullUrl = window.location.host + path;
      }
      const href = window.location.protocol + "//" + fullUrl;

      newSections.push({ name: name, path: path, fullUrl: href });
    }
    return newSections;
  }, [currentURL]);

  React.useEffect(() => {
    const currentHref = window.location.href;
    setCurrentURL(currentHref);
    setNavItems(getSectionsInUrl());
  }, [getSectionsInUrl, setCurrentURL]);

  React.useEffect(() => {
    setInterval(() => {
      if (currentURL !== window.location.href) {
        setCurrentURL((state) => window.location.href);
      }
    }, 500);
  }, [currentURL]);

  return (
    <S.NavContainer>
      {navItems.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <span
              id={"nav-" + item.name}
              style={{
                cursor: i === navItems.length - 1 ? "default" : "pointer",
                fontSize: navItems.length >= 5 ? "1rem" : "1.1rem",
              }}
              onClick={() => {
                if (item.path !== "/") window.location.assign(item.fullUrl);
                else window.location.assign("/");
              }}
            >
              {item.name}
            </span>
            {i !== navItems.length - 1 && <p>/</p>}
          </React.Fragment>
        );
      })}
    </S.NavContainer>
  );
};

export { NavBar };
