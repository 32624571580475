import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const ChartSlice = createSlice({
  name: "app-slice",
  initialState: {
    triggerAnimation: false,
    showModal: false,
    navigationHistory: ["Navigate"],
    test: "",
  },

  reducers: {
    setTriggerAnimation: (state, action: PayloadAction<boolean>) => {
      state.triggerAnimation = !state.triggerAnimation;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = !state.showModal;
    },
    pushToNavHistory: (state, action: PayloadAction<string>) => {
      state.navigationHistory.push(action.payload);
    },
    popToNavHistory: (state, action: PayloadAction<string>) => {
      if (state.navigationHistory.length === 1) return;
      state.navigationHistory.pop();
    },
    resetNavHistory: (state, action: PayloadAction<string>) => {
      state.navigationHistory = ["Navigate"];
    },
  },
});

export const {
  setTriggerAnimation,
  setShowModal,
  popToNavHistory,
  pushToNavHistory,
  resetNavHistory,
} = ChartSlice.actions;
export default ChartSlice.reducer;
