import { Route, Router, Routes } from "react-router-dom";
import { createMemoryHistory, History } from "history";
import "./App.css";
import { Home } from "./app/pages/home";
import { NavBar } from "./app/pages/components/nav_bar";
import Dlt360Logo from "./assets/dlt-logo.svg";
import { logoBackground } from "./app/styles/app_colors";
import { ActionBar } from "./app/pages/components/action_bar";

export const history: History = createMemoryHistory();
function App() {
  return (
    <div className="App">
      <Router navigator={history} location={history.location}>
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "absolute",
                    width: "100%",
                    zIndex: 999,
                    height: "8rem"
                  }}
                >
                  <section
                    className="sc-top-left-header"
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      height: "100%"
                    }}
                  >
                    <div
                      className="dv-img-holder"
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        background: logoBackground,
                        borderBottomRightRadius: "10%",
                        height: "100%"
                      }}
                    >
                      <img
                        src={Dlt360Logo}
                        alt="a"
                        style={{
                          width: "90%",
                          height: "80%",
                          margin: "1rem 2rem"
                        }}
                      />
                    </div>
                    <div
                      className="navbar-holder"
                      style={{
                        zIndex: 9999,
                        display: "flex",
                        alignSelf: "flex-start",
                        height: "100%"
                      }}
                    >
                      <NavBar />
                    </div>
                  </section>
                  <div
                    className="actionbar-holder"
                    style={{ display: "flex", height: "100%", alignItems: "center", marginRight: "1rem" }}
                  >
                    <ActionBar />
                  </div>
                </div>
                <Home />
              </>
            }
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
