import React from "react";
import { Spin, Container } from "./styles";
const Spinner: React.FunctionComponent = () => {
  return (
    <Container className="spinner">
      <Spin />
    </Container>
  );
};

export { Spinner };
