import React from 'react';

interface OutisderCallbackerProps {
  children: React.ReactNode;
  clickOutsideEvent: (value: React.SetStateAction<boolean>) => void;
}
const OutsiderCallbacker: React.FunctionComponent<OutisderCallbackerProps> = ({ children, clickOutsideEvent }) => {
  function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
    React.useEffect(() => {
      function handleClickOutside(event: MouseEvent): void {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          clickOutsideEvent(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);
  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsiderCallbacker;
