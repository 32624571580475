import axios from "axios";
import { RemoteConfig } from "../shared/types";
import { ChartApiEndpoints } from "../utilts/constants/api_endpoints";

class RemoteConfigService {
  async getRemoteConfig(): Promise<RemoteConfig> {
    const { data } = await axios.get(
      new ChartApiEndpoints().getRemoteConfigEndpoint
    );
    return data;
  }
}

export { RemoteConfigService };
