import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import appSlice from "./slices/app_slice.store";
import chartSlice from "./slices/chart_slice.store";

const reducer = combineReducers({
  appSlice,
  chartSlice,
});

const rootStore = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export { rootStore };
