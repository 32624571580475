import styled from "styled-components";

export const Container = styled.div`
  overflow-y: scroll;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  margin-top: 5rem;
  height: 100vh;
  width: 100%;
  margin-top: 5vh;

  h1 {
    color: white;
  }

  .chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
  }
  
  .chart-container svg {
    height: 100%;
  }
`;

export const FirstStateContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 250px;
    width: 250px;
    /* animation: pulse1 2s ease-in-out infinite; */
    cursor: pointer;

    @keyframes pulse1 {
      0% {
        opacity: 0;
        transform: scale(0.8);
      }

      30% {
        opacity: 1;
        transform: scale(1);
      }

      60% {
        opacity: 1;
        transform: scale(1.2);
      }

      100% {
        opacity: 0;
        transform: scale(1.2);
      }
    }

    @keyframes pulse2 {
      0% {
        transform: scale(1, 1);
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
      }
    }
  }
`;
