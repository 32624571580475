import styled from "styled-components";
import { modalBackground } from "../../../styles/app_colors";

export const Container = styled.section`
  background-color: ${modalBackground};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
  margin-right: 50%;
  border-radius: 8px;

  .icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #2d2d2d;
    padding: 0.5rem 0.7rem;
    margin-right: 20px;
    cursor: pointer;

    :last-child {
      margin-right: 0px;
    }

    :hover {
      background-color: #4a4a4a;
    }
  }
`;
