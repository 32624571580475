import React from "react";
import { Sunburst } from "../components/sunburst_chart/sunburst-v3";

import { Container, FirstStateContainer } from "./style";
import axios from "axios";
import { ChartApiEndpoints } from "../../utilts/constants/api_endpoints";
import { ReactComponent as NavigatorMainCircle } from "../../../assets/navigator-main.svg";

const Home: React.FunctionComponent = () => {
  const [chartData, setChartData] = React.useState<any>(null);
  const [hasClickedOnce, setHasClickedOnce] = React.useState(false);
  const [apiChartData, setApiChartData] = React.useState<any>(null);
  const [isFirstAccess, setIsFirstAccess] = React.useState(true);

  const addSectionToURL = React.useCallback(
    (fullString: string, name: string) => {
      let currentHref = window.location.href;
      let newCode = `/${fullString}`;
      let newURL = currentHref + `/${fullString}`;
      if (newURL.substring(8).includes("//")) {
        newCode = fullString;
      }

      window.history.pushState(null, "", currentHref + newCode);
    },
    []
  );

  const removeSectionFromURL = React.useCallback(() => {
    let currentHref = window.location.href;
    let newURL = currentHref.substring(0, currentHref.lastIndexOf("/"));
    window.history.pushState(null, "", newURL);
  }, []);

  function limitDataToTwoLevels(data: any): any {
    if (data.children) {
      const depth2Children = data.children;
      data.children = depth2Children.map((depth2Child: any) => {
        if (depth2Child.children) {
          const depth3Children = depth2Child.children.map((item: any) => {
            if (item.children) {
              return { ...item, children: null, hasChildren: true };
            }
            return { ...item, hasChildren: false };
          });
          return {
            ...depth2Child,
            children: depth3Children,
            hasChildren: true,
          };
        }
        return { ...depth2Child, hasChildren: false };
      });
      data.hasChildren = true;
    } else {
      data.hasChildren = false;
    }
    return data;
  }

  const getChartData = React.useCallback(async () => {
    const data = await axios
      .get(new ChartApiEndpoints().getChartEndpoint)
      .then((res) => {
        setApiChartData(res.data);
        return res.data;
      });
    var ApiData = limitDataToTwoLevels({ ...data });

    const newChartData = ApiData;
    setChartData(newChartData);
  }, []);

  const recursivelyIterateThroughChart = React.useCallback(
    (sectionClickedFullString: string, data: any): object | null => {
      if (data.fullString === sectionClickedFullString) {
        return data;
      }

      if (data.children) {
        for (let i = 0; i < data.children.length; i++) {
          const child = data.children[i];
          const result: any = recursivelyIterateThroughChart(
            sectionClickedFullString,
            child
          );
          if (result) {
            return result;
          }
        }
      }

      return null;
    },
    []
  );
  const recursivelyIterateThroughChartToFindParent = React.useCallback(
    (sectionClickedFullString: string, data: any) => {
      if (sectionClickedFullString === "Navigator") {
        return data;
      }
      if (sectionClickedFullString === data.fullString) {
        return data;
      }
      if (data.children) {
        for (let i = 0; i < data.children.length; i++) {
          const child = data.children[i];
          if (child.fullString === sectionClickedFullString) {
            return data;
          }
          const result: any = recursivelyIterateThroughChartToFindParent(
            sectionClickedFullString,
            child
          );
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    []
  );

  const getCodesInURL = React.useCallback((): string[] => {
    const codes = window.location.href.split("/");
    const codesInURL = codes.filter(
      (code) =>
        code.length > 6 &&
        !code.includes("digital-navigato") &&
        !code.includes("localhost")
    );
    return codesInURL;
  }, []);

  const setChartDataBasedOnURL = React.useCallback(() => {
    const codesInURL = getCodesInURL();
    if (codesInURL.length > 0) {
      const lastCode = codesInURL[codesInURL.length - 1].replace(/%20/g, " ");
      if (lastCode) {
        const newData = recursivelyIterateThroughChart(lastCode, apiChartData);
        setChartData(limitDataToTwoLevels({ ...newData }));
      }
    }
    setIsFirstAccess(false);
  }, [apiChartData, getCodesInURL, recursivelyIterateThroughChart]);

  const setsUrlOnLoad = React.useCallback(() => {
    const href = window.location.href
      .replace(/%5B%5B/g, "[[")
      .replace(/%5D%5D/g, "]]");
    window.history.pushState(null, "", href);
  }, []);

  React.useEffect(() => {
    getChartData();
    setsUrlOnLoad();
  }, [getChartData, setsUrlOnLoad]);

  React.useEffect(() => {
    if (isFirstAccess && apiChartData) {
      setChartDataBasedOnURL();
      console.log(window.location.pathname);
      if (window.location.pathname !== "/") {
        setHasClickedOnce(true);
      }
    }
  }, [isFirstAccess, setChartDataBasedOnURL, apiChartData]);

  const updateChartDataOnClickForward = React.useCallback(
    async (sectionFullString: string) => {
      const newData = recursivelyIterateThroughChart(
        sectionFullString,
        apiChartData
      );
      setChartData(limitDataToTwoLevels({ ...newData }));
    },
    [apiChartData, recursivelyIterateThroughChart]
  );
  const updateChartDataOnClickBack = React.useCallback(
    async (sectionFullString: string) => {
      const newData = recursivelyIterateThroughChartToFindParent(
        sectionFullString,
        apiChartData
      );

      setChartData(limitDataToTwoLevels({ ...newData }));
    },
    [apiChartData, recursivelyIterateThroughChartToFindParent]
  );

  return (
    <Container className="dv-home">
      {!hasClickedOnce && (
        <FirstStateContainer>
          <NavigatorMainCircle onClick={() => setHasClickedOnce(true)} />
        </FirstStateContainer>
      )}
      {chartData && hasClickedOnce && (
        <section className="chart-container">
          <Sunburst
            data={chartData}
            keyId="chart"
            width={600}
            onNextSectionClickCallback={(sectionFullString: string) =>
              updateChartDataOnClickForward(sectionFullString)
            }
            onCenterClickCallback={(sectionFullString: string) =>
              updateChartDataOnClickBack(sectionFullString)
            }
            addSectionToURL={(code: string, name: string) => {
              return addSectionToURL(code, name);
            }}
            removeSectionFromURL={(code: string) => removeSectionFromURL()}
          />
        </section>
      )}
    </Container>
  );
};

export { Home };
