import styled from "styled-components";
import { pinkHover } from "../../../styles/app_colors";

export const NavContainer = styled.nav`
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem;

  p {
    font-weight: 700;
    font-family: inter;
    color: ${pinkHover};
  }
  span {
    color: white;
    font-family: inter;
    font-weight: 300;
    margin: 0px 6px;

    :first-child {
      margin-left: 0px;
    }
  }
`;
