import { logEvent } from "firebase/analytics";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { analytics } from "../../../../../shared/services/firebase";
import { Content } from "../../types/content";

import * as S from "./styles";

const ModalContent: React.FunctionComponent<{
  content: Content;
  title: string;
}> = ({ content, title }) => {
  return (
    <S.Container className="content">
      <ReactMarkdown
        components={{ a: (props) => LinkRenderer({ title, ...props }) }}
        remarkPlugins={[remarkGfm]}
        children={content.text}
      />
    </S.Container>
  );
};

function LinkRenderer(props: any) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        return logEvent(
          analytics,
          `Clicked Link on ${props.title} | clicked: ${props.href}`,
          {
            link: props.href,
            title: props.title,
          }
        );
      }}
    >
      {props.children}
    </a>
  );
}

export { ModalContent };
