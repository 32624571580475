// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAnTSOhPAOOmEeKVM0cZ38FMM4pXYbbwEQ",
  authDomain: "digital-navigator-9ff7e.firebaseapp.com",
  projectId: "digital-navigator-9ff7e",
  storageBucket: "digital-navigator-9ff7e.appspot.com",
  messagingSenderId: "899612042652",
  appId: "1:899612042652:web:1bc15b5aefbefec880d459",
  measurementId: "G-5H5XM2WW4Q",
  cookieFlags: "samesite=none,secure",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
