import axios from "axios";
import React from "react";
import { Spinner } from "../../../shared/ui/spinner";
import { ChartApiEndpoints } from "../../../utilts/constants/api_endpoints";
import { ModalContent } from "./components/content";

import * as S from "./styles";
import { Content } from "./types/content";

interface SunburstModalProps {
  fullCode: string;
  title: string;
}

const SunburstModal: React.FunctionComponent<SunburstModalProps> = ({
  fullCode,
  title,
}) => {
  const [content, setContent] = React.useState<Content | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  function renderContent() {
    return content ? (
      <ModalContent content={content!} title={title} />
    ) : (
      <p>
        Coming soon - No content in category. Sorry. We could not find any
        information for this section. You can proceed to the next one or go
        back.
      </p>
    );
  }

  const getContentData = React.useCallback(async () => {
    setIsLoading(true);
    const formatedCode = fullCode.match(/([A-Z])\w+/g)
    if (formatedCode) {
      const { data } = await axios.get(
        new ChartApiEndpoints().fetchContentEndpoint(formatedCode[0])
      );
      if (data) {
        setContent({ code: data.code, text: data.text });
      }
    }
    setIsLoading(false);
  }, [fullCode]);

  React.useEffect(() => {
    getContentData();
  }, [getContentData]);
  return <S.Container>{isLoading ? <Spinner /> : renderContent()}</S.Container>;
};

export { SunburstModal };
