import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import NavigatorCircle from "../../../../assets/navigator-circle.svg";
import ChartCenterCircle from "../../../../assets/chart-center-circle.svg";
import ChartMediumCircle from "../../../../assets/chart-medium-circle.svg";
import ChartLargeCircle from "../../../../assets/chart-large-circle.svg";
import ChartLargeCircleClosed from "../../../../assets/chart-large-circle-closed.svg";
import { pinkHover } from "../../../styles/app_colors";
import Modal from "../modal";
import { SunburstModal } from "../sunburst_modal";
import { transition } from "d3-transition";
import { useDispatch } from "react-redux";
import {
  popToNavHistory,
  pushToNavHistory,
} from "../../../stores/slices/chart_slice.store";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../shared/services/firebase";

function getTransition(duration) {
  return transition().duration(duration).ease(d3.easeLinear);
}

const partition = (data) => {
  const minValue = 3;
  let root = d3.hierarchy(data);
  root.value = 360;
  root.eachBefore((d) => {
    if (d.parent) {
      const parentNode = d.parent;
      let childrenSum = 0;
      parentNode.children.forEach((child) => {
        childrenSum += child.children
          ? child.children.length > minValue
            ? child.children.length
            : minValue
          : minValue;
      });
      d.value =
        (d.parent.value / childrenSum) *
        (d.children
          ? d.children.length > minValue
            ? d.children.length
            : minValue
          : minValue);
    }
  });
  const partition = d3.partition().size([2 * Math.PI, root.height + 1])(root);
  return partition;
};

export const Sunburst = (props) => {
  const svgRef = useRef();
  var childClicked = useRef();
  var centerClicked = useRef();
  const [currentParent, setCurrentParent] = React.useState(null);
  const [fullCode, setFullCode] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [goForward, setGoForward] = React.useState(false);
  const [goBack, setGoBack] = React.useState(false);
  const dispatch = useDispatch();

  const renderSunburst = React.useCallback(() => {
    const { data, width } = props;

    document.querySelectorAll("g").forEach((node) => {
      node.remove();
    });
    const circleRadius = 70;
    const radius = width / 6;
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [0, 0, width, width])
      .style("font", "10px sans-serif");

    var defs = svg.append("svg:defs");

    defs
      .append("pattern")
      .attr("id", "center-background")
      .append("image")
      .attr("xlink:href", ChartCenterCircle);

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${width / 2})`);

    const root = partition(data);
    root.each((d) => (d.current = d));

    const path = g
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d, _i) => {
        while (d.depth > 1) d = d.parent;
        return "transparent";
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr("d", (d) => {
        const arc = d3
          .arc()
          .startAngle((d) => {
            return d.x0;
          })
          .endAngle((d) => d.x1)
          .innerRadius((d) => (d.y0 <= 1 ? circleRadius : d.y0 * radius))
          .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))(
          d.current
        );
        return arc;
      })
      .attr("id", function (d) {
        return `arc-${d.data.fullString
          .split(" ")
          .join("")
          .replace(/[^\w\s]/gi, "")}`;
      })
      .on("mouseenter", handleMouseEnterSection)
      .on("mouseleave", handleMouseLeaveSection);

    path
      .filter((d) => d.depth >= 1)
      .style("cursor", "pointer")
      .on("click", (_e, p) => {
        setChildClickedValues(p);
      });

    //GRAPH LABELS
    const label = g
      .append("g")
      .attr("pointer-events", "none")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("fill-opacity", (_d) => +true)
      .attr("transform", (d) => labelTransform(d))
      .style("font-family", "Inter")
      .style("font-weight", 400)
      .style("font-size", (d) => (d.depth === 1 ? "1.2em" : "1em"))
      .text((d) => d.data.name)
      .attr("data-width", (d) => d.x1 - d.x0)
      .attr("id", function (d) {
        return `label-${d.data.fullString
          .split(" ")
          .join("")
          .replace(/[^\w\s]/gi, "")}`;
      })
      .call(wrap, 60);

    g.append("circle")
      .datum(root)
      .attr("r", circleRadius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .style("cursor", "pointer")
      .on("click", (_e, p) => {
        setParentClickedValues(p);
      });

    //MIDDLE LABEL
    g.append("text")
      .datum(root)
      .attr("id", "mainCircleText")
      .attr("className", (d) => `main-circle-${d.data.name}`)
      .text((d) => d.data.name)
      .attr("x", 0)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .attr("fill", "white")
      .style("font-family", "Inter")
      .style("font-weight", 600)
      .style("font-size", "1.3em")
      .attr("pointer-events", "none")
      .call(wrap, 60);

    function setChildClickedValues(p) {
      setCurrentParent(p);
      setFullCode(p.data.full_code);
      setShowModal(true);
      logEvent(analytics, `Clicked Section: ${p.data.name}`, {
        section: p.data.name,
      });
    }

    function setParentClickedValues(p) {
      setCurrentParent(p);
      setGoBack(true);
      setGoForward(false);
      props.onCenterClickCallback(p.data.fullString ?? "Navigator");
    }

    centerClicked.current = function () {
      label.attr("fill-opacity", (_d) => +false);
      navigationTransition();
      dispatch(popToNavHistory());
    };

    childClicked.current = function (p) {
      label.attr("fill-opacity", (_d) => +false);
      navigationTransition();
      dispatch(pushToNavHistory(p.data.name));
    };

    function navigationTransition() {
      g.select("#mainCircleText")
        .transition(getTransition(200))
        .attr("fill-opacity", (_d) => +false)
        .transition(getTransition(400))
        .attr("fill-opacity", (_d) => +true);

      d3.selectAll("#large-circle")
        .transition(getTransition(400))
        .style("visibility", +"hidden")
        .transition(getTransition(10))
        .style("scale", "0")
        .transition(getTransition(10))
        .style("visibility", +"visible")
        .transition(getTransition(400))
        .style("scale", "1");

      d3.selectAll("#large-circle-close")
        .transition(getTransition(400))
        .style("visibility", +"hidden")
        .transition(getTransition(10))
        .style("scale", "0")
        .transition(getTransition(10))
        .style("visibility", +"visible")
        .transition(getTransition(400))
        .style("scale", "1");

      d3.selectAll("#medium-circle")
        .transition(getTransition(400))
        .style("visibility", +"hidden")
        .transition(getTransition(10))
        .style("scale", "0")
        .transition(getTransition(10))
        .style("visibility", +"visible")
        .transition(getTransition(400))
        .style("scale", "1");

      d3.selectAll("#navigator-circle")
        .transition(getTransition(400))
        .style("rotate", `${360 * Math.random()}deg`);

      label
        .transition(getTransition(400))
        .attr("fill-opacity", (_d) => +false)
        .transition(getTransition(200))
        .attrTween("transform", (d) => () => labelTransform(d))
        .transition()
        .duration(300)
        .delay(1200)
        .attr("fill-opacity", +true);
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelTransform(d) {
      const current = d.current;
      const x = (((current.x0 + current.x1) / 2) * 180) / Math.PI;
      const y = ((current.y0 + current.y1) / 2) * radius;
      var dx = x - 90;
      var dz = x < 180 ? 0 : 180;
      if (d.parent.children.length === 1 && d.depth === 1) {
        dx = 0;
        dz = 0;
      }
      return `rotate(${dx}) translate(${
        d.y0 <= 1 ? y - 20 : y
      },0) rotate(${dz}) `;
    }

    function handleMouseEnterSection(_, p) {
      if (p.depth >= 1) {
        const parentNameWithoutSpaces = p.data.fullString
          .split(" ")
          .join("")
          .replace(/[^\w\s]/gi, "");
        const parentLabel = d3.select(`#label-${parentNameWithoutSpaces}`);
        const parentLabelChildren = parentLabel.selectChildren();
        const parentArcChildren = parentLabel.selectChildren();
        if (
          parentLabelChildren &&
          parentLabelChildren._groups[0][0].__data__.children
        ) {
          const children = parentLabelChildren._groups[0][0].__data__.children;
          const childrenArcs =
            parentArcChildren._groups[0][0].__data__.children;
          if (children && childrenArcs) {
            children.forEach((child) => {
              const name = `label-${child.data.fullString
                .split(" ")
                .join("")
                .replace(/[^\w\s]/gi, "")}`;
              const arcName = `arc-${child.data.fullString
                .split(" ")
                .join("")
                .replace(/[^\w\s]/gi, "")}`;
              const selection = d3.select(`#${name}`);
              const arcSelection = d3.select(`#${arcName}`);
              selection.attr("font-size", "2em");
              selection.attr("font-weight", 1000);
              arcSelection
                .filter(function (_d) {
                  const conditional = +this.getAttribute("fill-opacity");
                  return conditional;
                })
                .attr("fill", pinkHover)
                .attr("fill-opacity", "0.1");
              const parentChildren = parentArcChildren.selectChildren();
              parentChildren
                .filter(function (_) {
                  const conditional = +this.getAttribute("fill-opacity");
                  return conditional;
                })
                .attr("fill", "white");
            });
          }
        }
      }
    }

    function handleMouseLeaveSection(_, p) {
      if (p.depth >= 1) {
        const parentNameWithoutSpaces = p.data.fullString
          .split(" ")
          .join("")
          .replace(/[^\w\s]/gi, "");
        const parentLabel = d3.select(`#label-${parentNameWithoutSpaces}`);
        const parentLabelChildren = parentLabel.selectChildren();
        const parentArcChildren = parentLabel.selectChildren();
        if (
          parentLabelChildren &&
          parentLabelChildren._groups[0][0].__data__.children
        ) {
          const children = parentLabelChildren._groups[0][0].__data__.children;
          const childrenArcs =
            parentArcChildren._groups[0][0].__data__.children;
          if (children && childrenArcs) {
            children.forEach((child) => {
              const arcName = `arc-${child.data.fullString
                .split(" ")
                .join("")
                .replace(/[^\w\s]/gi, "")}`;
              const arcSelection = d3.select(`#${arcName}`);
              arcSelection.attr("fill", "transparent");
            });
          }
        }
      }
    }

    return (
      <>
        <div
          id={props.keyId}
          className="text-center"
          style={{ height: "100%" }}
        >
          <svg
            ref={svgRef}
            id={`${props.keyId}-svg`}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </>
    );
  }, [dispatch, props]);

  useEffect(() => {
    renderSunburst();
    if (goForward && !goBack && !showModal) {
      childClicked.current(currentParent);
      logEvent(
        analytics,
        `Navigated to next section: ${currentParent.data.name}`,
        {
          section: currentParent.data.name,
        }
      );

      props.addSectionToURL(
        currentParent.data.fullString ?? "",
        currentParent.data.name
      );
    } else if (goBack && !goForward && !showModal) {
      centerClicked.current();
      logEvent(analytics, `Navigated back: ${currentParent.data.name}`, {
        section: currentParent.data.name,
      });
      props.removeSectionFromURL();
    }
  }, [currentParent, showModal, goForward, goBack, props, renderSunburst]);

  useEffect(() => {
    const svg = d3.select("#chart-svg");
    const border = d3.select("#label");

    svg
      .append("image")
      .attr("xlink:href", NavigatorCircle)
      .attr("width", props.width / 4.55)
      .attr("height", props.width / 4.55)
      .attr("id", "navigator-circle")
      .style("transform-origin", "center")
      .style("transform", `translate(234px, 234px)`);

    svg
      .append("image")
      .attr("xlink:href", ChartCenterCircle)
      .attr("width", props.width / 4)
      .attr("height", props.width / 4)
      .attr("id", "center-circle")
      .attr("class", "circle")
      .style("transform-origin", "center")
      .style("transform", `translate(225px, 225px)`);

    svg
      .append("image")
      .attr("xlink:href", ChartMediumCircle)
      .attr("width", props.width / 1.5)
      .attr("height", props.width / 1.5)
      .attr("id", "medium-circle")
      .attr("class", "circle")
      .style("transform-origin", "center")
      .style("scale", "1")
      .style("transform", `translate(100px, 100px)`);

    svg
      .append("image")
      .attr("xlink:href", ChartLargeCircle)
      .attr("width", props.width)
      .attr("height", props.width)
      .attr("id", "large-circle")
      .attr("class", "circle")
      .style("transform-origin", "center")
      .attr("transform", `translate(0, 00)`);

    svg
      .append("image")
      .attr("xlink:href", ChartLargeCircleClosed)
      .attr("width", props.width)
      .attr("height", props.width)
      .attr("id", "large-circle-close")
      .attr("class", "circle")
      .style("transform-origin", "center")
      .attr("transform", `translate(0, 00)`);

    border
      .append("circle")
      .attr("r", 100)
      .attr("cx", 50)
      .attr("cy", 0)
      .attr("fill", "red")
      .attr("id", "border");

    d3.selectAll("#navigator-circle")
      .transition()
      .duration(1000)
      .delay(function (_d) {
        d3.select(this).lower();
      });

    d3.selectAll("#center-circle")
      .transition()
      .duration(1000)
      .delay(function (_d) {
        d3.select(this).lower();
      });

    d3.selectAll("#medium-circle")
      .transition()
      .duration(1000)
      .delay(function (_d) {
        d3.select(this).lower();
      });

    d3.selectAll("#large-circle")
      .transition()
      .duration(1000)
      .delay(function (_d) {
        d3.select(this).lower();
      });

    d3.selectAll("#large-circle-close")
      .transition()
      .duration(1000)
      .delay(function (_d) {
        d3.select(this).lower();
      });
  }, [props.width]);

  useEffect(() => {
    var circleVisibility = "hidden";
    if (props.data) {
      var childrenCount = 0;
      props.data.children.forEach((child) => {
        if (child.children) {
          childrenCount++;
        }
      });
      if (childrenCount > 0) {
        circleVisibility = "visible";
      }
    }

    d3.selectAll("#large-circle").attr(
      "visibility",
      circleVisibility === "hidden" ? "visible" : "hidden"
    );
    d3.selectAll("#large-circle-close").attr("visibility", circleVisibility);
  }, [props.data]);

  const renderModal = () => {
    return (
      <>
        {showModal && (
          <Modal
            setShowModal={setShowModal}
            title={currentParent.data.name}
            showArrow={currentParent.data.hasChildren}
            onClickArrow={() => {
              setGoForward(true);
              setGoBack(false);
              props.onNextSectionClickCallback(currentParent.data.fullString);
              setShowModal(false);
            }}
            onClickRadar={() => {
              setShowModal(false);
              setGoForward(false);
              setGoBack(false);
            }}
            onClickFeedback={() => {
              setShowModal(false);
              setGoForward(false);
              setGoBack(false);
            }}
            onClickPresentation={() => {
              setShowModal(false);
              setGoForward(false);
              setGoBack(false);
            }}
            onClickClose={() => {
              setShowModal(false);
              setGoForward(false);
              setGoBack(false);
            }}
          >
            <SunburstModal
              fullCode={fullCode}
              title={currentParent.data.name}
            />
          </Modal>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        width: "80%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      {renderSunburst()}
      <>{renderModal()}</>
    </div>
  );
};

function wrap(text, width) {
  text.each(function () {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineHeight = 1, // ems
      y = text.attr("y"),
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", 0.35 + "em");
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width && line.length > 1) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", `${lineHeight + 0.35}em`)
          .text(word);
      }
    }
    var spans = text.selectAll("tspan").nodes();
    if (spans.length > 1) {
      spans.forEach((span) => {
        if (span.attributes.dy.value !== `${lineHeight + 0.35}em`) {
          span.attributes.dy.value = `-${(
            0.5 *
            (spans.length - 1)
          ).toString()}em`;
        }
      });
    }
  });
}
